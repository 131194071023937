<template>
  <div class="toolbar">
    <slot></slot>
  </div>
</template>

<style lang="scss">
  .toolbar {
    width: 100%;
    padding: 8px;
    background-color: #ffffff;
    overflow: hidden;
    line-height: 32px;
    border: 1px solid #e6ebf5;
    .el-date-editor{
      vertical-align: middle;
    }
  }
</style>
