<template>
  <div id="app">
    <router-view name="blank"></router-view>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>
 
<style lang="scss">
@import 'assets/css/main.css';
@import 'assets/css/icon.css';

#app {
  background-color: #222d32;
}

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

a {
  color: #3c8dbc;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #367fa9;
}

.el-menu .fa {
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  text-align: center;
}

.el-menu .fa:not(.is-children) {
  font-size: 14px;
}

.gray-mode {
  filter: grayscale(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/**全局样式*/
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.el-popper.is-dark {
  max-width: 400px !important; //控制dark颜色的ToolTip
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.w100 {
  max-width: 117px;
}

.w200 {
  max-width: 217px;
}

.w300 {
  max-width: 317px;
}

.text-gray {
  color: #999;
}

.text-orange {
  color: rgb(255, 72, 0);
}

.text-red {
  color: rgb(149, 42, 0);
}

.text-green {
  color: rgb(0, 149, 7);
}

.el-drawer__body {
  overflow: auto;
}


.tips {
  font-size: 12px;
  color: #999;
  line-height: 16px;
}

.data-tips {
  color: #999;
  line-height: 2;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
}

.user-list {
  padding-bottom: 60px;
}

.text-cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** col2 */
.col2 {
  width: 50%;
  display: inline-block;
}

.main-title {
  font-size: 12px;
  color: #999;
  line-height: 1.2;
  margin: 0 0 10px 0;
  width: 100%;
}

.my-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  padding-top: 0;
}

.my-drawer__footer {
  display: flex;
}

.my-drawer__content form {
  flex: 1;
}

.my-drawer__footer button {
  flex: 1;
}

/**时间关联选择中的至 */
.el-date-editor .el-range-separator {
  font-size: 11px;
}

.toolbar>div+div,
.toolbar>div>span+span,
.toolbar>button {
  margin-left: 5px;
}

.toolbar .el-range-editor.el-input__inner {
  padding: 2px 10px;
}

.toolbar .right {
  float: right;

  div+div {
    margin-left: 10px;
  }
}

.page-header {
  font-size: 18px;
  line-height: 36px;
  border-bottom: 0.5px solid #eee;
  margin-bottom: 15px;
}
</style>
