import { http } from "../utils/http";
import { getData } from "../utils/get";
export default {
  getSaasStaffs: (params) => http.post(`api/admin/saas/listStaffs`, params).then(getData),
  getSaasRolePermission: (params) => http.post(`api/admin/saas/allPermisson`, params).then(getData),
  editSaasStaffRole: (params) => http.post(`api/admin/saas/editRole`, params).then(getData),
  editSaasStaffUser: (params) => http.post(`api/admin/saas/editUser`, params).then(getData),
  editSaasPermissionGroup: (params) => http.post(`api/admin/saas/editUserPermissionGroup`, params).then(getData),
  getSaasStaffUsers: (params) => http.post(`api/admin/saas/listUsers`, params).then(getData),
  editSaasProduct: (params) => http.post(`api/admin/saas/saasProduct`, params).then(getData),

  //setting
  saasTemplate: (params) => http.post(`api/admin/base/message/template`, params).then(getData),
  saasEditTemplate: (params) => http.post(`api/admin/base/message/edit`, params).then(getData),
  saasLogs: (params) => http.post(`api/admin/base/message/logs`, params).then(getData),

  saasGetAmountList: (params) => http.post(`api/admin/finance/get_amount_list`, params).then(getData),
  saasGetWithdrawList: (params) => http.post(`api/admin/finance/get_withdraw_list`, params).then(getData),
  saasGetWithdrawPut: (params) => http.post(`api/admin/finance/withdraw_put`, params).then(getData),
};
