import { createRouter, createWebHistory } from "vue-router";
/*浏览器上面进度条*/
import NProgress from "nprogress";
import { isLogin, removeToken } from "@/utils/dataStorage";
import Personal from "./personal";
import app from "./app";
import app_register from "./app_register";
import app_check from "./app_check";
import app_collect from "./app_collect";
import app_page from "./app_page";
import saas_content from "./saas_content";
import saas_setting from "./saas_setting";
import system from "./system";

NProgress.configure({
  showSpinner: false, // 是否显示加载ico
});

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
      keepAlive: true,
    },
    component: () => import("@/views/layout/App.vue"),
    children: [
      {
        path: "/",
        name: "Dashboard",
        meta: {
          title: "首页",
          keepAlive: true,
        },
        component: () => import("@/views/home/Index.vue"),
      },

      //个人中心，可能有修改密码，头像修改等路由
      ...Personal,
      ...app,
      ...app_register,
      ...app_check,
      ...app_collect,
      ...app_page,
      ...saas_content,
      ...saas_setting,
      ...system,
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "后台登录",
      keepAlive: true,
    },
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/content/read/:keyword",
    name: "content.read",
    meta: {
      title: "查看内容",
      keepAlive: true,
    },
    component: () => import("@/views/content/Read.vue"),
  },
  {
    path: "/screen/signin/:itemId/:roomId?",
    name: "screen.signin",
    meta: {
      title: "大屏",
      keepAlive: true,
    },
    component: () => import("@/views/screen/signin.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //如果设置标题就显示，没设置使用默认
  if (null != to.meta && null != to.meta.title && "" !== to.meta.title) {
    document.title = to.meta.title || process.env.VUE_APP_APPNAME;
  } else {
    document.title = process.env.VUE_APP_APPNAME;
  }
  //浏览器上方显示进度条
  NProgress.start();

  if (!isLogin() && to.path != "/login") {
    removeToken();
    next({ path: "/login" });
  } else {
    next(); //正常放行
  }
});

router.afterEach(() => {
  //关闭浏览器上方的进度条
  NProgress.done();
});

export default router;
