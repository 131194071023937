import Storage from 'good-storage' 


export default {
    namespaced: true,
    state:{ 
        connect:0, 
        unRead:0,
        userList:Storage.session.get('chat_userList') || [],
    },
    getters: { 
        connect: state => state.connect,
        unRead: state => state.unRead,
        userList: state => state.userList, 
 
    },
    mutations: { 
        UPDATA_CONNECT(state, param) {
            state.connect = param
        },
        UPDATA_UN_READ(state, param) {
            state.unRead = param
        }, 
        UPDATA_USER_LIST(state, param) { 
            state.userList = param
            Storage.session.set('chat_userList',param)
        }, 
 
    },
    actions:{ 
        updateConnect ({ commit }, param) {
            commit('UPDATA_CONNECT', param)
        },
        updateUnRead ({ commit }, param) {
            commit('UPDATA_UN_READ', param)
        },
        updateUserList ({ commit }, param) {
            commit('UPDATA_USER_LIST', param)
        }, 
 
    }
}