export default [ 
    {
        path: '/app/page/work/:id',
        name: 'app.page.work',
        meta: {
          title: '营销工作台',
          keepAlive: true
        },
        component: () => import('@/views/AppPage/work/work.vue'),
      }, 
      {
        path: '/app/page/list/:id',
        name: 'app.page.list.setting',
        meta: {
          title: '营销设置',
          keepAlive: false
        },
        component: () => import('@/views/AppPage/setting/setting.vue'),
      }, 

        
  
  
]