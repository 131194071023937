export default [
  {
    path: "/app/prodct/list",
    name: "app.product.list",
    meta: {
      title: "产品设置",
      keepAlive: true,
    },
    component: () => import("@/views/base/product.vue"),
  },
  {
    path: "/app/finance/withdraw",
    name: "app.finance.withdraw",
    meta: {
      title: "代收提现",
      keepAlive: true,
    },
    component: () => import("@/views/base/withdraw.vue"),
  },
  {
    path: "/app/base/action",
    name: "app.base.main",
    meta: {
      title: "功能设置",
      keepAlive: true,
    },
    component: () => import("@/views/base/action.vue"),
  },
  {
    path: "/content/user",
    name: "content.user",
    meta: {
      title: "全部用户",
      keepAlive: true,
    },
    component: () => import("@/views/users/index.vue"),
  },
  {
    path: "/user/level/list",
    name: "user.level.list",
    meta: {
      title: "用户头衔",
      keepAlive: true,
    },
    component: () => import("@/views/users/level.vue"),
  },
  {
    path: "/content/user/th3",
    name: "content.user_th3",
    meta: {
      title: "第三方用户",
      keepAlive: true,
    },
    component: () => import("@/views/users/th3.vue"),
  },
  {
    path: "/admin_manage",
    name: "AdminManage",
    meta: {
      title: "管理员列表",
      keepAlive: true,
    },
    component: () => import("@/views/system/admin/list.vue"),
  },
  {
    path: "/role_manage",
    name: "RoleManage",
    meta: {
      title: "角色列表",
      keepAlive: true,
    },
    component: () => import("@/views/system/role/Role.vue"),
  },
  {
    path: "/permission_list",
    name: "PermissionList",
    meta: {
      title: "权限列表",
      keepAlive: true,
    },
    component: () => import("@/views/system/permission/Index.vue"),
  },

  {
    path: "/setting/items",
    name: "SettingItemList",
    meta: {
      title: "配置参数",
      keepAlive: true,
    },
    component: () => import("@/views/system/settingitem/list.vue"),
  },
  {
    path: "/setting/cache",
    name: "SettingCache",
    meta: {
      title: "缓存大师",
      keepAlive: true,
    },
    component: () => import("@/views/system/cache/list.vue"),
  },
  {
    path: "/setting/operate_logs",
    name: "SettingOperateLogs",
    meta: {
      title: "操作日志",
      keepAlive: true,
    },
    component: () => import("@/views/system/cache/operate_logs.vue"),
  },
  {
    path: "/setting/runing_logs",
    name: "RuningLogs",
    meta: {
      title: "调试日志",
      keepAlive: true,
    },
    component: () => import("@/views/system/cache/logs.vue"),
  },

  {
    path: "/sys/message/template",
    name: "sys.message.template",
    meta: {
      title: "消息模板",
      keepAlive: true,
    },
    component: () => import("@/views/system/message/template.vue"),
  },
  {
    path: "/sys/message/logs",
    name: "sys.message.logs",
    meta: {
      title: "发送记录",
      keepAlive: true,
    },
    component: () => import("@/views/system/message/logs.vue"),
  },
];
