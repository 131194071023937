import { http } from "../utils/http";
import { getData } from "../utils/get";
export default {
  AppSetting: (params) => http.post(`api/admin/app/setting`, params).then(getData),
  resetAppBaseAgree: (params) => http.post(`api/admin/app/reset_agree`, params).then(getData),

  SiteHome: (params) => http.post(`api/admin/app/siteHome`, params).then(getData),
  SiteInit: (params) => http.post(`api/admin/app/siteInit`, params).then(getData),
  uploadFile: (params) => http.post(`api/admin/imageUpload`, params).then(getData),

  getMyWechat: (params) => http.post(`api/admin/app/weChat`, params).then(getData),

  getMyItems: (params) => http.post(`api/admin/item/getMyItems`, params).then(getData), //获得指定分类的全部应用
  getMyItem: (params) => http.post(`api/admin/item/getMyItem`, params).then(getData), //获得指定ID单个应用
  deleteItem: (params) => http.post(`api/admin/item/itemDelete`, params).then(getData), //删除指定
  getAppCategorys: (params) => http.post(`api/admin/common/category/list`, params).then(getData),
  editAppCategory: (params) => http.post(`api/admin/common/category/edit`, params).then(getData),
  deleteAppCategory: (params) => http.post(`api/admin/common/category/delete`, params).then(getData),

  getAppProducts: (params) => http.post(`api/admin/base/product/list`, params).then(getData),
  editAppProduct: (params) => http.post(`api/admin/base/product/edit`, params).then(getData),

  //代收提现处理
  getWithdrawLists: (params) => http.post(`api/admin/finance/get_withdraw_lists`, params).then(getData),
  getWithdrawOp: (params) => http.post(`api/admin/finance/withdraw_op`, params).then(getData),

  getAppNews: (params) => http.post(`api/admin/base/news/list`, params).then(getData),
  editAppNew: (params) => http.post(`api/admin/base/news/edit`, params).then(getData),
  deleteAppNew: (params) => http.post(`api/admin/base/news/delete`, params).then(getData),
  getAppWords: (params) => http.post(`api/admin/base/words/list`, params).then(getData),
  editAppWord: (params) => http.post(`api/admin/base/words/edit`, params).then(getData),
  deleteAppWord: (params) => http.post(`api/admin/base/words/delete`, params).then(getData),

  getQuestionLists: (params) => http.post(`api/admin/base/question/list`, params).then(getData),
  editQuestion: (params) => http.post(`api/admin/base/question/edit`, params).then(getData),
  pagePreLoad: (params) => http.post(`api/admin/base/preload`, params).then(getData),
  getNotices: (params) => http.post(`api/admin/content/notice/list`, params).then(getData),
  sendNotice: (params) => http.post(`api/admin/content/notice/send`, params).then(getData),
  deleteNotice: (params) => http.post(`api/admin/content/notice/delete`, params).then(getData),
  getSystemNotice: (params) => http.post(`api/admin/content/notice/system_list`, params).then(getData),
  readSystemNotice: (params) => http.post(`api/admin/content/notice/system_read`, params).then(getData),
  getBanners: (params) => http.post(`api/admin/content/banner/list`, params).then(getData),
  editBanner: (params) => http.post(`api/admin/content/banner/edit`, params).then(getData),
  deleteBanner: (params) => http.post(`api/admin/content/banner/delete`, params).then(getData),
  getPages: (params) => http.post(`api/admin/content/page/list`, params).then(getData),
  getPageInfo: (params) => http.post(`api/admin/content/page/get`, params).then(getData),
  editPage: (params) => http.post(`api/admin/content/page/edit`, params).then(getData),
  deletePages: (params) => http.post(`api/admin/content/page/delete`, params).then(getData),

  getRegisterRooms: (params) => http.post(`api/admin/app/register/room_list`, params).then(getData), //ROOM
  editRegisterRoom: (params) => http.post(`api/admin/app/register/room_edit`, params).then(getData), //ROOM
  deleteRegisterRoom: (params) => http.post(`api/admin/app/register/room_delete`, params).then(getData), //ROOM
  editRegisterRoomTime: (params) => http.post(`api/admin/app/register/room_time_edit`, params).then(getData), //ROOM
  editRegisterRoomJudges: (params) => http.post(`api/admin/app/register/room_judges_edit`, params).then(getData), //ROOM
  deleteRegisterRoomTime: (params) => http.post(`api/admin/app/register/room_time_delete`, params).then(getData), //ROOM
  initRegisterRoomGroup: (params) => http.post(`api/admin/app/register/init_room_group`, params).then(getData),
  getRegisterRoomGroups: (params) => http.post(`api/admin/app/register/room_group_list`, params).then(getData),
  chnageRegisterRoomTimeItems: (params) => http.post(`api/admin/app/register/room_time_item`, params).then(getData),
  cutRegisterRoomGroup: (params) => http.post(`api/admin/app/register/room_group_cut`, params).then(getData),
  removeCutRegisterRoomGroup: (params) => http.post(`api/admin/app/register/room_group_remove_cut`, params).then(getData),
  findUserRegisterRoomGroup: (params) => http.post(`api/admin/app/register/find_user`, params).then(getData),
  cutUserOneRegisterRoomGroup: (params) => http.post(`api/admin/app/register/cut_user_one`, params).then(getData),
  exportRegisterRoom: (params) => http.post(`api/admin/app/register/room_export`, params).then(getData),
  sendGroupChange: (params) => http.post(`api/admin/app/register/send_group_change`, params).then(getData),
  editData: (params) => http.post(`api/admin/app/register/editData`, params).then(getData),
  deleteData: (params) => http.post(`api/admin/app/register/deleteData`, params).then(getData),
  deleteJudgesLogData: (params) => http.post(`api/admin/app/register/deleteJudgesLogData`, params).then(getData),

  getRegisterPays: (params) => http.post(`api/admin/app/register/pay_list`, params).then(getData), //支付记录
  getRegisterJudges: (params) => http.post(`api/admin/app/register/judges_list`, params).then(getData), //JUDGES
  editRegisterJudges: (params) => http.post(`api/admin/app/register/judges_edit`, params).then(getData), //JUDGES
  signedLists: (params) => http.post(`api/admin/app/register/signed_list`, params).then(getData), //签到列表
  helpUserSign: (params) => http.post(`api/admin/app/register/signed_help`, params).then(getData), //签到列表
  judgesLogLists: (params) => http.post(`api/admin/app/register/judges_log_list`, params).then(getData), //评委日志
  judgesScoreEdit: (params) => http.post(`api/admin/app/register/judges_score_edit`, params).then(getData), //评委日志

  userScoreList: (params) => http.post(`api/admin/app/register/user_score_list`, params).then(getData), //成绩
  userScoreEdit: (params) => http.post(`api/admin/app/register/user_score_edit`, params).then(getData), //成绩
  scoreRank: (params) => http.post(`api/admin/app/register/score_rank`, params).then(getData), //成绩
};
