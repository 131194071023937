export default {
  sex: {
    1: "男",
    2: "女",
    0: "未知",
  },
  yn: [
    { value: 0, label: "否" },
    { value: 1, label: "是" }, 
  ],
  sceneTypes: [
    { value: "register", label: "比赛报名" },
    { value: "collect", label: "在线收款" },
    { value: "check", label: "考试评测" },
    { value: "page", label: "营销落地" },
  ],
  settingItemType: [
    { value: "list", label: "列表值" },
    { value: "value", label: "单一值" },
  ],
  messageItemType: [
    { value: "sms", label: "短信" },
    { value: "email", label: "邮件" },
    { value: "notice", label: "通知" },
  ],
  collectItemTypes: [
    { value: 0, label: "虚拟" },
    { value: 1, label: "实物" },
  ],
  collectItemSendTypes: [
    { value: 0, label: "不发货" },
    { value: 1, label: "快递/物流" },
    { value: 2, label: "联系客服" },
    { value: 3, label: "卡密发货" },
  ],
  registerStatusTypes: [
    { value: 0, label: "等待支付" },
    { value: 1, label: "支付完成" },
    { value: 2, label: "抽签完成" },
    { value: 3, label: "成绩查询" },
    { value: 4, label: "取消报名" },
  ],
  pointTypes: [
    { value: 1, label: "客服增加" },
    { value: 2, label: "客服减少" },
    { value: 3, label: "注册增送" },
    { value: 4, label: "分享增送" },
    { value: 5, label: "签到积分" },
    { value: 6, label: "通知赠送" },
    { value: 7, label: "资讯赠送" },
    { value: 8, label: "支付赠送" },
    { value: 9, label: "兑换抵扣" },
    { value: 10, label: "支付抵扣" },
  ],
  systemNoticeLevels: [
    { value: 1, label: "错误" },
    { value: 2, label: "警告" },
    { value: 3, label: "通知" },
    { value: 4, label: "普通" },
    { value: 5, label: "冗余" },
  ],
  cashTypes: [
    { value: 1, label: "待审核" },
    { value: 2, label: "通过" },
    { value: 3, label: "拒绝" },
    { value: 4, label: "成功" },
    { value: 5, label: "失败" },
  ],
  cashChannels: [
    { value: 1, label: "支付宝" },
    { value: 2, label: "微信" },
  ],
  todoTypes: [
    { value: "info", label: "一般" },
    { value: "warning", label: "重要" },
    { value: "error", label: "警报" },
    { value: "success", label: "完成" },
  ],
  remindItems: [
    { value: 0, label: "不提醒" },
    { value: 30, label: "提前30分钟" },
    { value: 60, label: "提前1小时" },
    { value: 120, label: "提前2小时" },
    { value: 240, label: "提前4小时" },
    { value: 1440, label: "提前1天" },
  ],
};
