import { createStore } from "vuex";
import app from "./modules/app";
import chat from "./modules/chat";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    chat,
  },
});
