export default [ 
      {
        path: '/app/item/list',
        name: 'app.item.list',
        meta: {
          title: '全部项目',
          keepAlive: true
        },
        component: () => import('@/views/AppItem/list.vue'),
      }, 
  
]