import localKey from "./localKey";
 
export default {
  nodeDevEnv: process.env.NODE_ENV == "development",
  apiPrefix: "",
  timeout: 5000,
  cookiesExpires: 7,
  requestRetry: 4,
  requestRetryDelay: 800,
  tokenKey: "ACCESS_TOKEN",
  userInfoKey: "USER_INFO",
  permissionsKey: "Authorization",
  gitHub: "",
  ...localKey
};
