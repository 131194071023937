import { ElMessageBox } from 'element-plus'

export default{
  confirm:function(
          {
              title = '提示',
              message = '是否继续？',
              type = 'info',//success / info / warning / error
              confirmButtonText = '确定', 
              cancelButtonText = '取消', 
              confirm = function () {},
              cancel = function () {}
          } = {}) {

        ElMessageBox.confirm(message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: type,
        })
        .then(() => {
          try {
            confirm();
          } catch (e) {
              console.error(e);
          }
        })
        .catch(() => {
          cancel();
        })
  },
  alert:function(
      {
        title = '提示',
        message = '', 
        confirmButtonText = '确定', 
        confirm = function () { }
      } = {}) { 
        ElMessageBox.alert(message, title, {
          confirmButtonText: confirmButtonText, 
          callback: (action) => {
            confirm(action)
          },
        }) 
  },
}
 