export default [ 
    {
        path: '/app/collect/work/:id',
        name: 'app.collect.work',
        meta: {
          title: '收集工作台',
          keepAlive: true
        },
        component: () => import('@/views/AppCollect/work/work.vue'),
      }, 
      {
        path: '/app/collect/list/:id',
        name: 'app.collect.list.setting',
        meta: {
          title: '收集设置',
          keepAlive: false
        },
        component: () => import('@/views/AppCollect/setting/setting.vue'),
      }, 

        
  
  
]