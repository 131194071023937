<template>
	<div class="pagination">
		<div style="display: inline-block;">
			<div v-if="showMultiple">
				<el-button @click="toggleSelection" size="small" style="margin-right:8px;">全选/取消</el-button>
				<el-dropdown trigger="click" placement="top-end" style="line-height: 32px;">
					<span class="el-dropdown-link">
						<el-button type="primary" size="small">批量操作 <el-icon style="margin-left:5px;">
								<ArrowDown />
							</el-icon>
						</el-button>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item v-for="(item, index) in multipleEvents" :key="index"
								@click="multipleClick(item)">
								{{ item.label }}</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
		<el-pagination v-model:currentPage="currentpage" v-model:page-size="pagesize" :page-sizes="[5, 10, 15, 30, 100]"
			:small="false" :background="false" layout="total, sizes, prev, pager, next, jumper" :total="total"
			@size-change="handleSizeChange" @current-change="handlePageChange" />

	</div>
</template>

<script>
export default {
	name: 'v-Pagination',
	props: {
		showMultiple: [Boolean],
		multipleEvents: [Array, Boolean],
		pagesize: [Number, String],//一页多少条
		currentpage: [Number, String],//当前页
		total: [Number, String],//总页数
		options: [Object],  // 分页发生变化赋值给options
		render: [Function], // 跳转触发的请求
		layout: {
			type: String,
			default: 'total, sizes, prev, pager, next, jumper'
		}
	},
	methods: {
		toggleSelection() {
			this.$emit("v-toggleSelection");
		},
		multipleClick(item) {
			this.$emit("v-multipleClick", item);
		}
	},
	setup(props, context) {
		// 分页导航
		const handlePageChange = (val) => {
			props.options.page = val;
			props.render();
		}
		//切换分页条数
		const handleSizeChange = (val) => {
			props.options.pageSize = val;
			props.render();
		}
		return {
			handlePageChange,
			handleSizeChange
		}
	}
}

</script>

<style scoped>
.pagination {
	padding: 10px;
	display: flex;
	background: #fff;
	flex-wrap: wrap;
	justify-content: space-between;
}
</style>

