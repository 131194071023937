export default [ 
    {
        path: '/app/check/work/:id',
        name: 'app.check.work',
        meta: {
          title: '评测工作台',
          keepAlive: true
        },
        component: () => import('@/views/AppCheck/work/work.vue'),
      }, 
      {
        path: '/app/check/list/:id',
        name: 'app.check.list.setting',
        meta: {
          title: '评测设置',
          keepAlive: false
        },
        component: () => import('@/views/AppCheck/setting/setting.vue'),
      }, 

        
  
  
]