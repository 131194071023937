import API from "@/api/";

export default async function getAppSettings() {
  let res = "";
  await new Promise((resolve) => {
    //请求
    API.AppSetting({ cmd: "get", keys: "base_by_saas" }).then((result) => {
      if (result.code == 200) {
        res = result.message;
      }
      resolve();
    });
  });
  return res;
}
