import { getToken } from "./dataStorage";
import store from "@/store";
import { ElMessageBox, ElMessage } from "element-plus";
import router from "../router";
export default class SocketService {
  /**
   * 单例
   */
  static instance = null;
  static get Instance() {
    if (!this.instance) {
      this.instance = new SocketService();
    }
    return this.instance;
  }

  // 和服务端连接的socket对象
  ws = null;

  // 存储回调函数
  callBackMapping = {};

  // 标识是否连接成功
  connected = false;

  // 记录重试的次数
  sendRetryCount = 0;

  // 重新连接尝试的次数
  connectRetryCount = 0;

  //是否可以连接
  connectStatus = 0;

  // 心跳包
  timeout = 15000;
  timeoutObj = null;

  //  定义连接服务器的方法
  connect() {
    console.log("开始连接");
    if (this.connectStatus === 1) return;

    // 连接服务器
    if (!window.WebSocket) return console.log("您的浏览器不支持WebSocket");

    this.ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
    // 连接成功的事件
    this.ws.onopen = () => {
      console.log("连接服务端成功");
      this.connected = true;

      // 重置重新连接的次数
      this.connectRetryCount = 0;

      //心跳包

      store.dispatch("chat/updateConnect", 1);
    };
    //   // 1.连接服务端失败
    //   // 2.当连接成功之后, 服务器关闭的情况(连接失败重连)
    this.ws.onclose = () => {
      console.log("连接服务端断开");
      store.dispatch("chat/updateConnect", 0);
      this.connected = false;
      this.connectRetryCount++;
      setTimeout(() => {
        this.connect();
      }, 500 * this.connectRetryCount);
    };

    // 得到服务端发送过来的数据
    this.ws.onmessage = (msg) => {
      //console.log('从服务端获取到了数据' + msg.data)
      let data = JSON.parse(msg.data);
      switch (data.type) {
        case "init": //要求登录
          this.login();
          break;
        case "ping": //心跳回复
          this.send({ type: "pong" });
          break;
        case "login": //登录失败了，Token错误
          if (data.code !== 200) {
            this.connectStatus = 1;
            ElMessageBox.alert("Socket 登录失败，请重新登录", "失败", {
              confirmButtonText: "明白",
              center: true,
              type: "warning",
              "show-close": false,
              callback: () => {
                //router.push({ path: "/login" });
                this.connectStatus = 0;
              },
            });
          } else {
            ElMessage.success("同步服务器 已连接。");
          }
          break;
        case 3:
          var userList = data.userConsultOrderVoList;
          var unRead = 0;
          for (let item of userList) {
            unRead += Number(item.unReaderNumber) || 0;
          }
          store.dispatch("chat/updateUnRead", unRead);
          store.dispatch("chat/updateUserList", data.userConsultOrderVoList);
          break;
        default:
          if (typeof this.callBackMapping == "function") {
            this.callBackMapping(data);
          }
          break;
      }
    };
  }

  // 回调函数的注册
  registerCallBack(callBack) {
    //console.log('回调函数的注册 ...ok')
    this.callBackMapping = callBack;
  }

  // 取消某一个回调函数
  unRegisterCallBack() {
    //console.log('取消某一个回调函数')
    this.callBackMapping = null;
  }

  // 发送数据的方法
  send(data) {
    //token
    var _data = typeof data === "object" ? data : {};
    _data.token = getToken();
    if (!_data.type) return;
    console.log("发送的数据 ", _data);
    // 判断此时此刻有没有连接成功
    if (this.connected) {
      this.sendRetryCount = 0;
      this.ws.send(JSON.stringify(_data));
    } else {
      this.sendRetryCount++;
      setTimeout(() => {
        this.send(JSON.stringify(_data));
      }, this.sendRetryCount * 500);
    }
  }

  //登录动作
  login() {
    this.send({ type: "login", data: { group: "screen" } });
  }

  // 主动心跳检测 根据服务器需求使用
  heartbeat() {
    let _this = this;
    window.heatTimer && clearInterval(window.heatTimer);
    window.heatTimer = setInterval(() => {
      _this.send({ type: 1 });
    }, this.timeout);
  }
}

/**
   * 
   * created
   * 
   // 注册回调函数
    this.$socket.registerCallBack(this.testData)

	this.$socket.send(‘这是客户端发送的数据’)

   */
