export default [
  {
    path: "/base/news",
    name: "base.news",
    meta: {
      title: "新闻资讯",
      keepAlive: true,
    },
    component: () => import("@/views/base/news.vue"),
  },
  {
    path: "/content/banner",
    name: "content.banner",
    meta: {
      title: "Banner广告",
      keepAlive: true,
    },
    component: () => import("@/views/content/Banner.vue"),
  },
  {
    path: "/content/question/list",
    name: "content.question.list",
    meta: {
      title: "常见问题",
      keepAlive: true,
    },
    component: () => import("@/views/content/Question.vue"),
  },
  {
    path: "/content/words/list",
    name: "content.words.list",
    meta: {
      title: "评语管理",
      keepAlive: true,
    },
    component: () => import("@/views/content/words.vue"),
  },
  {
    path: "/content/page",
    name: "content.page",
    meta: {
      title: "协议单页",
      keepAlive: true,
    },
    component: () => import("@/views/content/Page.vue"),
  },
  {
    path: "/content/notice",
    name: "content.notice",
    meta: {
      title: "平台公告",
      keepAlive: true,
    },
    component: () => import("@/views/content/Notice.vue"),
  },

  {
    path: "/content/system/notice",
    name: "content.system/notice",
    meta: {
      title: "系统通知",
      keepAlive: true,
    },
    component: () => import("@/views/content/systemNotice.vue"),
  },
];
