export default [
  {
    path: "/personal",
    name: "Personal",
    meta: {
      title: "个人信息",
      keepAlive: true,
    },
    component: () => import("@/views/personal/Index.vue"),
  },

  {
    path: "/user/feedback/list",
    name: "user.feedback.list",
    meta: {
      title: "用户反馈",
      keepAlive: true,
    },
    component: () => import("@/views/users/feedback.vue"),
  },

  {
    path: "/user/point/log",
    name: "user.point.log",
    meta: {
      title: "积分日志",
      keepAlive: true,
    },
    component: () => import("@/views/users/point.vue"),
  },

  {
    path: "/finance/pay",
    name: "finance.pay",
    meta: {
      title: "账户总览",
      keepAlive: true,
    },
    component: () => import("@/views/finance/pay.vue"),
  },
  {
    path: "/finance/payment",
    name: "finance.payment",
    meta: {
      title: "支付流水",
      keepAlive: true,
    },
    component: () => import("@/views/finance/payment.vue"),
  },
  {
    path: "/finance/withdraw",
    name: "finance.withdraw",
    meta: {
      title: "代收提现",
      keepAlive: true,
    },
    component: () => import("@/views/finance/withdraw.vue"),
  },
  {
    path: "/finance/withdraw/log",
    name: "finance.withdraw.log",
    meta: {
      title: "代收记录",
      keepAlive: true,
    },
    component: () => import("@/views/finance/help_log.vue"),
  },
];
