import axios from "axios";
import validate from "./validate";
import AES from "./AES";
import element from "./element";
import { ElMessage, formatter } from "element-plus";
import qee from "qf-export-excel";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Shanghai");
export default {
  setCache: function (key, value, expires = 0) {
    let start = (new Date().getTime() + "").substr(0, 10);
    let ls = window.localStorage;
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    if (expires > 0) {
      let v = {
        value: value,
        cacheTime: expires,
        startTime: start,
      };
      ls.setItem(key, JSON.stringify(v));
    } else {
      ls.setItem(key, value);
    }
  },
  getCache: function (key) {
    var ls = window.localStorage;
    if (ls) {
      var v = ls.getItem(key);
      if (!v) return;
      let item;
      try {
        item = JSON.parse(v);
      } catch (error) {
        //如果不行就不是json的字符串，就直接返回
        return v;
      }
      if (item && Object.prototype.hasOwnProperty.call(item, "startTime") && item.startTime && item.cacheTime) {
        let date = (new Date().getTime() + "").substr(0, 10);
        //何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
        if (date - item.startTime > item.cacheTime) {
          ls.removeItem(key); //缓存过期，清除缓存，返回false
          return false;
        } else {
          try {
            item = JSON.parse(item.value);
          } catch (error) {
            item = item.value; //如果不行就不是json的字符串，就直接返回
          }
          return item;
        }
      } else {
        //如果没有设置失效时间，直接返回值
        return item;
      }
    }
  },
  //移出缓存
  removeCache: function (key) {
    localStorage.removeItem(key);
  },
  removeCacheByKey(key) {
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).indexOf(key) >= 0) {
        console.log("find:", localStorage.key(i));
        localStorage.removeItem(localStorage.key(i));
      }
    }
  },
  //移出全部缓存
  clearCache() {
    localStorage.clear();
  },

  copy(message) {
    //创建input标签
    var input = document.createElement("input");
    //将input的值设置为需要复制的内容
    input.value = message;
    //添加input标签
    document.body.appendChild(input);
    //选中input标签
    input.select();
    //执行复制
    document.execCommand("copy");
    //成功提示信息
    ElMessage.success("内容已复制");
    //移除input标签
    document.body.removeChild(input);
  },
  exCity: function (key, data, separator) {
    let result = [];
    let _separator = separator || "";
    let pObj = data.find(function (obj) {
      return obj.value === key[0];
    });
    if (pObj) {
      result.push(pObj.label);
      if (pObj.children) {
        let cObj = pObj.children.find(function (obj) {
          return obj.value === key[1];
        });
        if (cObj.label != "市辖区" && cObj.label != pObj.label) result.push(cObj.label);
        if (cObj.children) {
          let aObj = cObj.children.find(function (obj) {
            return obj.value === key[2];
          });
          if (aObj) {
            result.push(aObj.label);
          }
        }
      }
    }
    return _separator ? result.join(_separator) : result;
  },
  sexLabel: function (key) {
    if (!key || key == "0") return "未知";
    return key == "1" ? "男" : "女";
  },
  getLabelByData: function (val, data, find, result) {
    let _find = find || "value";
    let _result = result || "label";
    //  let val=Number(key) || false
    let Obj = data.find(function (obj) {
      return obj[_find] === val;
    });
    return Obj ? Obj[_result] : "未知";
  },

  oninput: function (num) {
    var str = num;
    var len1 = str.substr(0, 1);
    var len2 = str.substr(1, 1);
    //如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 != ".") {
      str = str.substr(1, 1);
    }
    //第一位不能是.
    if (len1 == ".") {
      str = "";
    }
    //限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
      var str_ = str.substr(str.indexOf(".") + 1);
      if (str_.indexOf(".") != -1) {
        str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
      }
    }
    //正则替换
    str = str.replace(/[^\d^.]+/g, ""); // 保留数字和小数点
    str = str.replace(/.\d\d\d$/, ""); // 小数点后只能输两位
    return str;
  },
  cutStr: function (str, L, flag) {
    if (!str) return "";

    let _L = L || 10;
    let _flag = flag || "...";

    var result = "",
      strlen = str.length, // 字符串长度
      chrlen = str.replace(/[^\x00-\xff]/g, "**").length; // 字节长度

    if (chrlen <= _L) {
      return str;
    }

    for (var i = 0, j = 0; i < strlen; i++) {
      var chr = str.charAt(i);
      if (/[\x00-\xff]/.test(chr)) {
        j++; // ascii码为0-255，一个字符就是一个字节的长度
      } else {
        j += 2; // ascii码为0-255以外，一个字符就是两个字节的长度
      }
      if (j <= _L) {
        // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
        result += chr;
      } else {
        // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
        return result + _flag;
      }
    }
  },
  findByValue: function (arraytosearch, key, valuetosearch, returnIdx, isDelete) {
    for (var i = 0; i < arraytosearch.length; i++) {
      if (arraytosearch[i][key] === valuetosearch) {
        if (isDelete) {
          arraytosearch.splice(i, 1);
          return arraytosearch;
        } else {
          return returnIdx ? i : arraytosearch[i];
        }
      }
    }
    return returnIdx ? -1 : false;
  },
  removeByValue: function (arr, attr, value) {
    // 1数组 2属性 3属性值
    var index = -1;
    for (var i in arr) {
      if (arr[i][attr] == value) {
        index = i;
        break;
      }
    }

    var tmp = arr;
    if (index != -1) {
      tmp = arr.splice(index, 1);
    }

    return tmp;
  },

  randomCode: function () {
    //生成，日期+指定位2随机数的订单号
    function setTimeDateFmt(s) {
      // 个位数补齐十位数
      return s < 10 ? "0" + s : s;
    }
    const now = new Date();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let hour = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();
    month = setTimeDateFmt(month);
    day = setTimeDateFmt(day);
    hour = setTimeDateFmt(hour);
    minutes = setTimeDateFmt(minutes);
    seconds = setTimeDateFmt(seconds); //+ hour + minutes + seconds
    let orderCode =
      now.getFullYear().toString() +
      month.toString() +
      day +
      Math.round(Math.random() * 999)
        .toString()
        .padStart(3, "0");
    return orderCode;
  },
  fDate: function (e, more) {
    //生成年-月-日
    var d = new Date(e);
    var year = d.getFullYear();
    var month = change(d.getMonth() + 1);
    var day = change(d.getDate());
    var hour = change(d.getHours());
    var minute = change(d.getMinutes());
    var second = change(d.getSeconds());
    function change(t) {
      if (t < 10) {
        return "0" + t;
      } else {
        return t;
      }
    }
    return more ? year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second : year + "-" + month + "-" + day;
  },
  fmoney: function (s, type) {
    if (/[^0-9\.]/.test(s)) return "0.00";
    if (s == null || s == "null" || s == "") return "0.00";
    s = s.toString().replace(/^(\d*)$/, "$1.");
    s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
    s = s.replace(".", ",");
    var re = /(\d)(\d{3},)/;
    while (re.test(s)) s = s.replace(re, "$1,$2");
    s = s.replace(/,(\d\d)$/, ".$1");
    if (type == 0) {
      var a = s.split(".");
      if (a[1] == "00") {
        s = a[0];
      }
    }
    return s;
  },

  http(config) {
    return new Promise((resolve, reject) => {
      axios(config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("函数http请求失败");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  get(url, params = {}) {
    return this.http({
      method: "GET", // 请求方式
      url, // 请求路径
      params, // 请求参数
      //  responseType: 'blob', // 获取后台的二进制流生成Excel, 如果没有这个需求可以去掉
      headers: {
        // 请求头
        "Content-Type": "application/x-www-from-urlencoded",
      },
    });
  },
  fileSize: function (value) {
    if (null == value || value == "") {
      return "0 字节";
    }
    var unitArr = new Array("字节", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
    var index = 0;
    var srcsize = parseFloat(value);
    index = Math.floor(Math.log(srcsize) / Math.log(1024));
    var size = srcsize / Math.pow(1024, index);
    size = size.toFixed(2); //保留的小数位数
    return size + unitArr[index];
  },
  in_array: function (search, array) {
    for (var i in array) {
      if (array[i] == search) {
        return true;
      }
    }
    return false;
  },
  sortBy: function (attr, rev) {
    //第二个参数没有传递 默认升序排列
    if (rev == undefined) {
      rev = 1;
    } else {
      rev = rev ? 1 : -1;
    }

    return function (a, b) {
      a = a[attr];
      b = b[attr];
      if (a < b) {
        return rev * -1;
      }
      if (a > b) {
        return rev * 1;
      }
      return 0;
    };
  },
  openWindow: function (url) {
    window.open(url);
  },
  //比较时间的大小
  diffDate: function (e) {
    if (!e) return 0;
    var n = new Date();
    var d = new Date(e);
    return parseInt((d - n) / 1000 / 3600 / 24);
  },
  downloadImage: function (imgsrc, name) {
    //下载图片地址和图片名
    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  },
  exportExcel: function (titleList, dataSource, fileName) {
    return qee(titleList, dataSource, fileName);
  },
  dateAddDays(int) {
    return dayjs().add(int, "day").format("YYYY-MM-DD HH:mm:ss");
  },
  ...validate,
  ...AES,
  ...element,
};
