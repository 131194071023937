export default [
  {
    path: "/app/register/work/:id",
    name: "app.register.work",
    meta: {
      title: "报名工作台",
      keepAlive: true,
    },
    component: () => import("@/views/AppRegister/work/work.vue"),
  },

  // {
  //   path: '/app/register/list',
  //   name: 'app.register.list',
  //   meta: {
  //     title: '赛事项目',
  //     keepAlive: true
  //   },
  //   component: () => import('@/views/AppRegister/list.vue'),
  // },
  {
    path: "/app/register/list/:id",
    name: "app.register.list.show",
    meta: {
      title: "报名设置",
      keepAlive: false,
    },
    component: () => import("@/views/AppRegister/setting/setting.vue"),
  },
  // {
  //   path: '/app/register/data',
  //   name: 'app.register.data',
  //   meta: {
  //     title: '参赛数据',
  //     keepAlive: true
  //   },
  //   component: () => import('@/views/AppRegister/data.vue'),
  // },
  // {
  //   path: '/app/register/signed',
  //   name: 'app.register.signed',
  //   meta: {
  //     title: '签到列表',
  //     keepAlive: true
  //   },
  //   component: () => import('@/views/AppRegister/signed.vue'),
  // },

  // {
  //   path: '/app/register/room',
  //   name: 'app.register.room',
  //   meta: {
  //     title: '场馆排期',
  //     keepAlive: true
  //   },
  //   component: () => import('@/views/AppRegister/room.vue'),
  // },
  {
    path: "/app/register/judges",
    name: "app.register.judges",
    meta: {
      title: "人员信息",
      keepAlive: true,
    },
    component: () => import("@/views/AppRegister/judges.vue"),
  },
  {
    path: "/app/register/judges/logs",
    name: "app.register.judges.logs",
    meta: {
      title: "评审日志",
      keepAlive: true,
    },
    component: () => import("@/views/AppRegister/judgesLogs.vue"),
  },
  {
    path: "/app/register/union",
    name: "app.register.union",
    meta: {
      title: "推广伙伴",
      keepAlive: true,
    },
    component: () => import("@/views/AppRegister/union.vue"),
  },
  {
    path: "/app/register/union/log",
    name: "app.register.union.log",
    meta: {
      title: "伙伴日志",
      keepAlive: true,
    },
    component: () => import("@/views/AppRegister/unionLog.vue"),
  },
];
