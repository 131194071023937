import Storage from 'good-storage' 
import getWechats from './../actions/getWechats'
import getAppSettings from './../actions/getAppSettings'
import onRefreshPermission from './../actions/onRefreshPermission'

export default {
    namespaced: true,
    state:{ 
        token:Storage.session.get(process.env.VUE_APP_TOKEN_NAME) || "",
        userInfo:Storage.session.get(process.env.VUE_APP_USERINFO_NAME) || "",
        permissions:Storage.session.get(process.env.VUE_APP_PERMISSIONS_NAME) || "",
        menu:Storage.session.get('myNav') || [],
        AppSetting:Storage.session.get('mch_setting') || {},
        myWechats:Storage.session.get('myWechats') || [],
        unRead:Storage.session.get('unReadNotice') || 0,
        
    },
    getters: { 
        token: state => state.token,
        userInfo: state => state.userInfo,
        permissions: state => state.permissions,
        menu: state => state.menu,
        AppSetting: state => state.AppSetting,
        myWechats: state => state.myWechats,
        unRead: state => state.unRead,
         
    },
    mutations: {  
        UPDATA_TOKEN(state, param) { 
            state.token = param
            Storage.session.set(process.env.VUE_APP_TOKEN_NAME,param)
        },
        UPDATA_USER_INFO(state, param) {
            state.userInfo = param
            Storage.session.set(process.env.VUE_APP_USERINFO_NAME,param)
        },
        UPDATA_PERMISSIONS(state, param) {
            state.permissions = param
            Storage.session.set(process.env.VUE_APP_PERMISSIONS_NAME,param)
        },
        UPDATA_MENU(state, param) {
            state.menu = param
            Storage.session.set('myNav',param)
        },  
        UPDATA_MCH_SETTING(state, param) {
            state.AppSetting = param
            Storage.session.set('mch_setting',param)
        }, 
        UPDATA_MY_WECHATS(state, param) {
            state.myWechats = param
            Storage.session.set('myWechats',param)
        },  
        UPDATA_UNREAD_NOTICE(state, param) {
            state.unRead = param
            Storage.session.set('unReadNotice',param)
        },     
    },
    actions:{ 
        updateToken ({ commit }, param) {
            commit('UPDATA_TOKEN', param)
        },
        updateUserInfo ({ commit }, param) {
            commit('UPDATA_USER_INFO', param)
        },
        updatePermissions ({ commit }, param) {
            commit('UPDATA_PERMISSIONS', param)
        },
        updateMenu ({ commit }, param) {
            commit('UPDATA_MENU', param)
        },  
        updateAppSetting ({ commit }, param) {
            commit('UPDATA_MCH_SETTING', param)
        },  
        async getAppSetting({ commit }) {
            let item = await getAppSettings()
            commit('UPDATA_MCH_SETTING', item) 
        }, 
        async getMyWechats({ commit }) {
            let item = await getWechats()
            commit('UPDATA_MY_WECHATS', item) 
        },
        updateUnRead ({ commit }, param) {
            commit('UPDATA_UNREAD_NOTICE', param)
        },  
        async refreshPermission({commit}){
            let param = await onRefreshPermission()
            commit('UPDATA_PERMISSIONS', param['permission'])
            commit('UPDATA_MENU', param['menu']) 
        } 

    }
}