<template>
  <span>
    <span style="margin-right: 4px"><slot></slot></span>
    <el-tooltip :content="content" :placement="placement">
      <i :class="icon" style="cursor: pointer;"></i>
    </el-tooltip>
  </span>
</template>
<script>
  export default {
    name: 'v-HelpHint',
    props: {
      placement: {
        default: 'top'
      },
      icon: {type:[String,Boolean],default:'fa fa-question-circle-o'},
      content: String,
    },
    data() {
      return {}
    },
  }
</script>

