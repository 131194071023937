import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import store from "./store/";

import router from "./router/";
import Config from "./config/";
import { hasPermissions } from "@/utils/dataStorage";
import Utils from "./utils/index";
import App from "./App.vue";

const app = createApp(App);

app.config.globalProperties.CONF = Config;
app.config.globalProperties.COM = Utils;

//Vue.use(ElementUI)
app.use(ElementPlus, {
  locale: zhCn,
});

//WebSocket封装方法
import SocketService from "./utils/webSocket";
app.config.globalProperties.webSocket = SocketService.Instance;

//权限 在需要控制的地方使用v-permissions="'key'"
app.directive("permissions", {
  mounted(el, binding) {
    if (!hasPermissions(binding.value)) el.style.display = "none";
  },
});

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

//公共文件
import "font-awesome/css/font-awesome.min.css";

import API from "./api/";
app.config.globalProperties.API = API;

import Video from "video.js";
import "video.js/dist/video-js.css";

app.config.globalProperties.$video = Video;

import toolBar from "@/components/ToolBar.vue";
app.component("tool-bar", toolBar);
import vPagination from "@/components/Pagination.vue";
app.component("v-pagination", vPagination);
import vHelpHint from "@/components/HelpHint.vue";
app.component("v-help-hint", vHelpHint);
import SearchDateRange from "@/components/searchDateRange.vue";
app.component("search-date-range", SearchDateRange);

//import Preview from "@/components/PreviewItem/preview.js";
//app.use(Preview);

/*
import clipboard from 'clipboard';
app.config.globalProperties.clipboard = clipboard;
*/

app.use(store).use(router).mount("#app");
