<template>
  <div style="display: inline-block;">
    <el-date-picker style="width: 250px;" clearable unlink-panels size="small" v-model="search_date" type="daterange"
      range-separator="至" :start-placeholder="name + '起'" :end-placeholder="name + '止'" :shortcuts="shortcuts"
      value-format="YYYY-MM-DD HH:mm:ss">
    </el-date-picker>
  </div>
</template>
<script>
export default {
  name: 'searchDate',
  props: {
    name: { type: String, default: '创建时间' },
    value: {
      type: Array,
      default: () => { return [] },
    },
    placement: {
      default: 'top'
    },
  },
  emits: ['callback', 'clear'],
  data() {
    return {
      search_date: [],
      shortcuts: [{
        text: '前1天',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
          return [start, end];
        }
      }, {
        text: '前7天',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        }
      }, {
        text: '前14天',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(end.getTime() - 3600 * 1000 * 24 * 14);
          return [start, end];
        }
      }, {
        text: '前一月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        }
      }, {
        text: '前三月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        }
      }, {
        text: '前半年',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(end.getTime() - 3600 * 1000 * 24 * 180);
          return [start, end];
        }
      }],

    }
  },
  watch: {
    search_date() {
      this.$emit('callback', this.search_date)
    },
    value(val) {
      this.search_date = val
    }
  },
  methods: {}

}
</script>

