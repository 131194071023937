import { http } from "../utils/http";
import { getData } from "../utils/get";
import App from "./app.js";
import AppCollect from "./app_collect.js";
import Saas from "./saas.js";

export const getCaptcha = (params) => http.post(`api/admin/get/captcha`, params).then(getData);
export const login = (params) => http.post(`api/admin/authorizations`, params).then(getData);
export const register = (params) => http.post(`api/admin/register`, params).then(getData);
export const updatePassword = (params) => http.post(`api/admin/changePassword`, params).then(getData);
export const uploadImage = (params) => http.post(`/api/admin/imageUpload`, params).then(getData);
export const refreshPermission = (params) => http.post(`/api/admin/refreshPermission`, params).then(getData);

/**系统管理模块*/
export const getAdmins = (params) => http.post(`api/admin/admin/list`, params).then(getData);
export const getAdminRole = (params) => http.post(`api/admin/role/list`, params).then(getData);
export const editAdmins = (params) => http.post(`api/admin/admin/edit`, params).then(getData);
export const editAdminRole = (params) => http.post(`api/admin/role/edit`, params).then(getData);
export const editPermissionGroup = (params) => http.post(`api/admin/role/editRolePermission`, params).then(getData);
export const getPermissions = (params) => http.post(`api/admin/permission/list`, params).then(getData);
export const editPermissions = (params) => http.post(`api/admin/permission/edit`, params).then(getData);
export const updateSaasPermissions = (params) => http.post(`api/admin/permission/updateSaas`, params).then(getData);
export const getPermissionGroup = (params) => http.post(`api/admin/role/rolePermissionList`, params).then(getData);
export const getTodos = (params) => http.post(`api/admin/admin/getTodos`, params).then(getData);
export const editTodos = (params) => http.post(`api/admin/admin/editTodos`, params).then(getData);
export const getSettings = (params) => http.post(`api/admin/settings/list`, params).then(getData);
export const editSettings = (params) => http.post(`api/admin/settings/edit`, params).then(getData);

export const getMessageTemplate = (params) => http.post(`api/admin/message/listTemplate`, params).then(getData);
export const editMessageTemplate = (params) => http.post(`api/admin/message/editTemplate`, params).then(getData);
export const getMessageLogs = (params) => http.post(`api/admin/message/listLogs`, params).then(getData);

/**缓存 */

export const getCacheMan = (params) => http.post(`api/admin/settings/getCacheMan`, params).then(getData);
export const showCacheMan = (params) => http.post(`api/admin/settings/showCacheMan`, params).then(getData);
export const refreshCacheMan = (params) => http.post(`api/admin/settings/refreshCacheMan`, params).then(getData);
export const getRunLogs = (params) => http.post(`api/admin/settings/getRunLogs`, params).then(getData);
export const getOperateLogs = (params) => http.post(`api/admin/settings/getOperateLogs`, params).then(getData);

/** 应用开始 */
/** 应用公共的需要单独配置权限的要用单独接口 */
/** wechat */
export const taskLists = (params) => http.post(`api/admin/item/taskList`, params).then(getData);
export const taskCancel = (params) => http.post(`api/admin/item/taskCancel`, params).then(getData);
export const unionLists = (params) => http.post(`api/admin/item/unionList`, params).then(getData);
export const unionEdit = (params) => http.post(`api/admin/item/unionEdit`, params).then(getData);
export const unionResult = (params) => http.post(`api/admin/item/unionResult`, params).then(getData);

export const getWeappUri = (params) => http.post(`api/admin/app/getWeappUrl`, params).then(getData);

/** App Item */

export const getAppItemList = (params) => http.post(`api/admin/app/item/list`, params).then(getData);
export const editAppItem = (params) => http.post(`api/admin/app/item/edit`, params).then(getData);
export const reloadAppItem = (params) => http.post(`api/admin/app/item/reload`, params).then(getData);

/** 内容管理 */

/** 工单 */
export const workOrderList = (params) => http.post(`api/admin/workorder/list`, params).then(getData);
export const workOrderReply = (params) => http.post(`api/admin/workorder/reply`, params).then(getData);
/** 财务 */
export const getMoneyList = (params) => http.post(`api/admin/finance/get_money_list`, params).then(getData);
export const getPaymentList = (params) => http.post(`api/admin/finance/get_payment_list`, params).then(getData);

/**app: Register */
export const importRegisterGroup = (params) => http.post(`api/admin/app/register/importGroup`, params).then(getData);
export const importRegisterSongs = (params) => http.post(`api/admin/app/register/importSongs`, params).then(getData);
export const listSongs = (params) => http.post(`api/admin/app/register/listSongs`, params).then(getData);
export const editSongs = (params) => http.post(`api/admin/app/register/editSongs`, params).then(getData);
export const deleteSongs = (params) => http.post(`api/admin/app/register/deleteSongs`, params).then(getData);
export const getSongsLabel = (params) => http.post(`api/admin/app/register/getSongsLabel`, params).then(getData);
export const getRegisterGroup = (params) => http.post(`api/admin/app/register/listGroup`, params).then(getData);
export const editRegisterGroup = (params) => http.post(`api/admin/app/register/editGroup`, params).then(getData);
export const delRegisterGroup = (params) => http.post(`api/admin/app/register/delGroup`, params).then(getData);
export const getRegisterData = (params) => http.post(`api/admin/app/register/listData`, params).then(getData);
export const getRegisterDataByOne = (params) => http.post(`api/admin/app/register/listDataByOne`, params).then(getData);
export const deleteRegisterData = (params) => http.post(`api/admin/app/register/deleteData`, params).then(getData);
export const editRegisterData = (params) => http.post(`api/admin/app/register/editData`, params).then(getData);
export const importRegisterOfficeData = (params) => http.post(`api/admin/app/register/importOfficeData`, params).then(getData);
export const importRegisterResultData = (params) => http.post(`api/admin/app/register/importResultData`, params).then(getData);
export const exportRegisterData = (params) => http.post(`api/admin/app/register/exportData`, params).then(getData);
export const batchRegisterData = (params) => http.post(`api/admin/app/register/batch`, params).then(getData);

/*** 1。 用户管理 */
export const getUsers = (params) => http.post(`api/admin/content/user/list`, params).then(getData);
export const getTh3Users = (params) => http.post(`api/admin/content/user/list_th3`, params).then(getData);
export const editUser = (params) => http.post(`api/admin/content/user/edit`, params).then(getData);
export const getPointLogs = (params) => http.post(`api/admin/content/user/list_point_log`, params).then(getData);
export const getUserLevels = (params) => http.post(`api/admin/content/user/level_list`, params).then(getData);
export const editUserLevel = (params) => http.post(`api/admin/content/user/level_edit`, params).then(getData);

export default {
  getCaptcha,
  login,
  register,
  updatePassword,
  uploadImage,
  refreshPermission,
  getAdmins,
  getAdminRole,
  editAdmins,
  editAdminRole,
  editPermissionGroup,
  getOperateLogs,
  getPermissions,
  editPermissions,
  updateSaasPermissions,
  getPermissionGroup,
  getSettings,
  editSettings,
  getCacheMan,
  showCacheMan,
  refreshCacheMan,
  getRunLogs,
  getMessageTemplate,
  editMessageTemplate,
  getMessageLogs,

  //应用开始
  taskLists,
  taskCancel,
  unionLists,
  unionEdit,
  unionResult,
  getWeappUri,
  getAppItemList,
  editAppItem,
  reloadAppItem,
  workOrderList,
  workOrderReply,
  getPaymentList,
  getMoneyList,
  importRegisterGroup,
  importRegisterSongs,
  listSongs,
  editSongs,
  deleteSongs,
  getSongsLabel,
  getRegisterGroup,
  editRegisterGroup,
  delRegisterGroup,
  getRegisterData,
  getRegisterDataByOne,
  editRegisterData,
  deleteRegisterData,
  importRegisterOfficeData,
  importRegisterResultData,
  exportRegisterData,
  batchRegisterData,

  //内容管理
  getUsers,
  editUser,
  getTh3Users,
  getPointLogs,
  getUserLevels,
  editUserLevel,

  ...App,
  ...Saas,
  ...AppCollect,
};
