import { http } from '../utils/http' 
import { getData } from '../utils/get'
export default { 
	getUserOrderList:(params) => http.post(`api/admin/user/order/list`, params).then(getData),  
	userOrderEdit:(params) => http.post(`api/admin/user/order/edit`, params).then(getData),  
	userOrderClose:(params) => http.post(`api/admin/user/order/close`, params).then(getData),  
	
	listCollectItem:(params) => http.post(`api/admin/app/collect/list_item`, params).then(getData),  
	editCollectItem:(params) => http.post(`api/admin/app/collect/edit_item`, params).then(getData),  
	listCollectCard:(params) => http.post(`api/admin/app/collect/list_card`, params).then(getData),    
	importCollectCard:(params) => http.post(`api/admin/app/collect/import_card`, params).then(getData),  
	
    
	
    
}