import store from '@/store' 
import COM from '@/utils/index'

export function getUserInfo(key = null){
    let userInfo =  store.state.app.userInfo;
    if(key) return Object.prototype.hasOwnProperty.call(userInfo, key) ? userInfo[key] : null
    return userInfo
}


export function setUserInfo(user){
    return store.dispatch('app/updateUserInfo',user);
}

export function getToken() {
    return store.state.app.token;
}

export function setToken(token) {
    return store.dispatch('app/updateToken',token);
}

export function removeToken() {
    return store.dispatch('app/updateToken',"");
}

export function setMenu(menu) {
    return store.dispatch('app/updateMenu',menu);
} 

export function isLogin() {
    return (getToken() || '').length >5
}


export function setPermissions(permissionsArr){
    store.dispatch('app/updatePermissions',permissionsArr);
    return permissionsArr
}
export function hasPermissions(permissionsKey){
    if(COM.getCache('userId')==1) return true;
    let permissionsArr = store.state.app.permissions;
    return permissionsArr.indexOf(permissionsKey) != -1
}
