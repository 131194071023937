import axios from 'axios'
import qs from 'qs' 
import router from '../router'
import { ElMessage } from 'element-plus';
import {getToken,removeToken} from './dataStorage'
 
// allow use http client without Vue instance
export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  //baseURL: '/api/',
  timeout: 30000,
})


/**
 * axios请求拦截器
 * @param {object} config axios请求配置对象
 * @return {object} 请求成功或失败时返回的配置对象或者promise error对象
 * **/
http.interceptors.request.use(config => {
    config.headers['Content-Type']='application/x-www-form-urlencoded';
    config.data = qs.stringify(config.data);
   
    let token = getToken();
    if (token) { 
      config.headers.Authorization = `Bearer ${token}`;
    } 
    return config;
  }, err => {
    ElMessage.error({
      message: '请求失败了！',
      onClose: function () {}
    })
    return Promise.reject(err);
  })
  /**
   * axios 响应拦截器
   * @param {object} response 从服务端响应的数据对象或者error对象
   * @return {object} 响应成功或失败时返回的响应对象或者promise error对象
   **/
  http.interceptors.response.use(response => { 
    if(response.data && response.data.code===401){
      removeToken()
      setTimeout(function(){
        router.push({ name: 'Login' }) 
      },1500)  
    }
    
    if(response.data && !response.data.message && !response.data.message==0) response.data.message='message is null'

    return response
  }, error => {  
    // 超时处理
    if(error.message.includes('timeout') || error.message.includes('Network Error')){  
      ElMessage.error(`请求超时或者网络错误.`);
      return {code:201,msg:'timeout'}
    }
    let State = error.response.status;  
    ElMessage.error({
      message: '一帮二杆子正在袭击服务器，代号 '+State,
      onClose: function () {}
    })
    return Promise.resolve(error.response)
     
});
