import API from "@/api/";
 
export default async function onRefreshPermission() {
    let res = [] 
    await new Promise((resolve) => { 
        //请求
        API.refreshPermission({}).then((result)=>{
            if (result.code == 200) {
                res = result.message 
            }
            resolve()
        }) 
    })
    return res
}
 