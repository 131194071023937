export default [
  {
    path: "/base/personnel",
    name: "base.personnel",
    meta: {
      title: "员工管理",
      keepAlive: true,
    },
    component: () => import("@/views/base/personnel.vue"),
  },
  {
    path: "/base/message/template",
    name: "base.message.template",
    meta: {
      title: "消息模板",
      keepAlive: true,
    },
    component: () => import("@/views/base/message/template.vue"),
  },
  {
    path: "/base/message/logs",
    name: "base.message.logs",
    meta: {
      title: "短信记录",
      keepAlive: true,
    },
    component: () => import("@/views/base/message/logs.vue"),
  },
  {
    path: "/base/main/:tab?",
    name: "base.main",
    meta: {
      title: "企业设置",
      keepAlive: true,
    },
    component: () => import("@/views/base/main.vue"),
  },
  {
    path: "/base/other",
    name: "base.other",
    meta: {
      title: "其它设置",
      keepAlive: true,
    },
    component: () => import("@/views/base/other.vue"),
  },
];
